@font-face {
  font-family: 'ubuntu';
  src: url('../assets/ubuntu/UbuntuMono-R.ttf') format('truetype');
}

@font-face {
  font-family: 'roboto-bold';
  src: url('../assets/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'ubuntu-bold';
  src: url('../assets/ubuntu/UbuntuMono-B.ttf') format('truetype');
}

@font-face {
  font-family: 'opensans';
  src: url('../assets/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'basic';
  src: url('../assets/basis33.ttf') format('truetype');
}

body {
  /*
  font-family: opensans;
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  */
  font-family: ubuntu;
  margin: 0;
  width:100%;
  overflow-x: hidden;
  display: flex;
}

