
h1 { font-family: ubuntu; font-weight: 100; margin-bottom: 50px; }
h2 { font-family: ubuntu; font-weight: 100; margin-bottom: 50px; }
h3 { font-family: ubuntu; font-weight: 100; margin-bottom: 0px; }

.work-section h1 {margin-bottom: 70px;}
.work-section h2 {margin-bottom: 70px;}
.work-section h3 {margin-bottom: 70px;}

p {
  font-size: 22px;
  margin-bottom: 50px;
}

li {
  font-size: 22px;
  list-style: circle;
}

.background {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(212,219,255);
  background: linear-gradient(0deg, rgba(212,219,255,1) 0%, rgba(212,221,255,1) 0%, rgba(222,239,255,1) 5%, rgba(222,239,255,1) 8%, rgba(230,246,255,1) 12%, rgba(230,246,255,1) 16%, rgba(239,254,255,1) 20%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%);
}

.app {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  position: absolute;
  height: 100%;
}

.app-column {
  position: relative;
  text-align: center;
}

.app-header {
  font-size: calc(10px + 2vmin);
  margin: 0px 20px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.app-header.home {
  height: 100%;
}

.nav {
  height: 48px;
}

.nav-link {
  font-size: 20px;
  margin: 0px 20px;
}

.home.container {
  height: 100%;
  position: relative;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  height: 100%;
  padding: 50px 10% 0px 10%;
}

@media (min-height:550px) {
  .hero {
    padding: 100px 10% 0px 10%;
  }
}

@media (min-height:700px) {
  .hero {
    padding: 150px 10% 0px 10%;
  }
}

.home.simon {
  height: 200px;
}

@media (min-height:850px) {
  .home.simon {
    height: 400px;
  }
}

.drawing {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke-width: 3;
  animation: dash 3s ease-out forwards;
}

.drawing.sun {
  stroke-width: 2;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation:  dash 3s ease-in forwards;
  animation-delay: 0s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

a {
  outline: none;
}

.me-img {
  width: 300px;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .me-img {
    width: 200px;
  }
}

.work-section {
  margin-top: 70px;
}

ul.work-list {
  text-align: left;
  padding-left: 20px;
}

ul.work-list li {
  margin-bottom: 50px;
}

ul.more-list {
  text-align: center;
  margin-left: -10px;
  padding-left: 0px;
}

ul.more-list li {
  list-style: none;
  margin-bottom: 10px;
}
